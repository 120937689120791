import React, { Component } from 'react';
import CubeWorld from '../cuberun';

class GameThree extends Component {
    constructor(props){
        super(props)
        this.state = {
            scores : localStorage.getItem('highscores') ? JSON.parse(localStorage.getItem('highscores')) : [...Array(3).fill(0)]
        }
    }
    componentDidMount(){
        if(parseInt(this.state.scores[0])>25000){
            window.location.href = "/bdb012c3163a27d5026df209c5070a885445bf060c698025153c91f6fc1e2f85";
        }
    }
    render(){
        return <CubeWorld bgColor='#141622'/>;
    }
}

export default GameThree;