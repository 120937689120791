import React, { Component } from 'react';
import { sha256 } from 'js-sha256';
import queen from '../queen/queen.PNG';

class GameTwo extends Component {
    constructor(props){
        super(props);
        this.state = {
            givenans: ""
        }
        this.onchecker = this.onchecker.bind(this);
    }
    componentDidMount(){
        var st = "font-family: 'Road Rage', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; font-size: 2rem; color: #fe2079; text-shadow: 0 0 40px #fe2079;"
        console.log('%cDont worry, you cant hack this', st);
    }
    onchecker(){
        var gans = sha256(this.state.givenans);
        if((gans==="3dc213abd1e1b551dc027fc2c0dab346e9603de03679d05c4633b1fba9a820d7") || (gans==="d2775b86d93eb98e48a64bede70522fca494d7d428b2c440b62b7f8042d2cf7a")){
            window.location.href = "/46966cc5d23eb333a941df24b1ad8334a78a9db6d1092005ab846a29ad7d65a3";
        }else{
            alert("Tappu ri answeru");
        }
    }
    render() { 
        return (
            <div style={{ paddingTop: '150px' }}>
                <center>
                <h1 className="game__menu-button" style={{ paddingBottom: '30px', fontSize: '75px' }}>Neen hu annu</h1>
                    <div className="game__menu-button" style={{ paddingBottom: '30px', fontSize: '40px' }}>
                        Decode this shit
                    </div>
                    <img src={queen} style={{ width: '500px', borderRadius: '20px' }}/>
                    <div className="game__menu-warning" style={{ paddingBottom: '30px', fontSize: '20px' }}>Hint : Secret of Queen</div>

                    <div>
                        <input type="text" className="game__menu-button" style={{ borderColor: '#fe2079', boxShadow: '0 0 15px #fe2079', letterSpacing: '35px' }}
                            onChange={(e) => {this.setState({ givenans: e.target.value })}}
                        />
                    </div>
                    <div style={{ padding: '30px' }}>
                        <button className="game__menu-button" style={{ fontSize: '30px', boxShadow: '0 0 15px #fe2079', padding: '15px' }} onClick={() => {this.onchecker()}}>
                            Check Madi
                        </button>
                    </div>
                </center>
            </div>
        );
    }
}
 
export default GameTwo;