import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import Snake from 'react-simple-snake'

class GameOne extends Component {
    constructor(props){
        super(props);
        this.state = {
            snakehighscore : window.localStorage.getItem("snakeHighScore")
        }
    }
    componentDidMount(){
        if(this.state.snakehighscore === "50"){
            window.localStorage.removeItem("snakeHighScore");
            window.location.href = "/37e7702e5ce449c1225fa1b3b3ed8e2dff35d24464d9264a4c2bebf3e0deee05";
        }
    }
    state = {  }
    render() { 
        return (
            <Container>
                <Row>
                    <center>
                        <h2 style={{ color: '#ffffff' }}>Reach High score of 50</h2>
                    </center>
                </Row>
                <Row>
                    <center>
                        <div style={{ color: '#fe2079' , textShadow: '0 0 15px #fe2079'}}>
                            <Snake snakeColor="#fe2079" appleColor="#fe2079"/>
                        </div>
                        <div style={{ marginTop: '50px', color: '#ffffff' }}>Use Arrow keys to control the snake</div>
                    </center>
                </Row>
                <Row>
                    <center>
                        <div>
                            or just Hit Control+Shift+I and Click console.<br/>
                            Enter the following command to hack the game and refresh the page<br/><br/>

                            window.localStorage.setItem("snakeHighScore",50);
                        </div>
                    </center>
                </Row>
            </Container>
        );
    }
}
 
export default GameOne;