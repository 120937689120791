import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import GameOne from '../games/gameone';
import GameTwo from '../games/gametwo';
import GameThree from '../games/gamethree';
import EndGame from '../games/endgame';

function GamePage(){
    let { hash } = useParams();
    switch(hash){
        case "454349e422f05297191ead13e21d3db520e5abef52055e4964b82fb213f593a1":
            return <GameOne/>;
            break;
        case "37e7702e5ce449c1225fa1b3b3ed8e2dff35d24464d9264a4c2bebf3e0deee05":
            return <GameTwo/>;
            break;
        case "46966cc5d23eb333a941df24b1ad8334a78a9db6d1092005ab846a29ad7d65a3":
            return <GameThree/>;
            break;
        case "bdb012c3163a27d5026df209c5070a885445bf060c698025153c91f6fc1e2f85":
            return <EndGame/>;
            break;
        default:
            return <h1 className="game__menu-button" style={{ padding: '80px', fontSize: '100px' }}>Invalid link da</h1>
    }
}
export default GamePage;