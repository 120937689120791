import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import HomePage from './components/homepage';
import GamePage from "./components/gamepage";
import GameThree from "./games/gamethree";

import "./App.css";

class App extends React.Component{
    constructor(props){
        super(props)
        this.state={
            selected: false,
            hued: true
        }
    }
    render(){
        return(
            <Router>
                <div className="App">
                <Switch>
                    <Route path="/testfly" component={GameThree}/>
                    <Route path='/:hash' component={GamePage}/>
                    {/* <Route path='/game' component={GamePage}/> */}
                    <Route path="/" component={HomePage}/>
                </Switch>
                </div>
            </Router>
        );
    }
}

export default App;