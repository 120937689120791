import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

import moon from "../assets/moon.png";
import sad from "../assets/sad.mp4";


class HomePage extends React.Component{
    constructor(props){
        super(props)
        this.state={
            selected: false,
            hued: true
        }
    }
    render(){
        return(
            <Container fluid style={{ textAlign: 'center', paddingTop: '75px' }}>
                <h1 className="game__menu-button" style={{ padding: '80px', fontSize: '100px' }}>Neen Hu Annu</h1>
                <Row className="justify-content-center" style={{ paddingTop: '100px' }}>    
                    <Col md={2} className="justify-content-center">
                        {this.state.selected && 
                            <div>
                                {this.state.hued && <>
                                        <img src={moon} alt="Moon" height="400" onClick={() => {window.location.href = '/454349e422f05297191ead13e21d3db520e5abef52055e4964b82fb213f593a1'}}/>
                                        <Button className="game__menu-button" style={{ fontSize: '15px', background: 'transparent' }} onClick={() => {this.setState({ selected: false, hued: true })}}>Rethink It</Button>
                                    </>
                                }
                            </div>
                        }
                        {!this.state.selected && 
                            <Button className="game__menu-button" style={{ fontSize: '25px', background: 'transparent', width: '200px', height: '50px' }} onClick={() => {this.setState({ selected: true, hued: true })}}>Hu</Button>
                        }
                    </Col>
                    <Col md={2} className="justify-content-center">
                    {this.state.selected && 
                        <div>
                            {!this.state.hued && <>
                                <div className="game__menu-button" style={{ fontSize: '20px' }}>Plis Rethink it 😂</div>
                                <video id="video" width="400" src={sad} controls autoplay/>
                                <Button className="game__menu-button" style={{ fontSize: '15px', background: 'transparent' }} onClick={() => {this.setState({ selected: false, hued: true })}}>Rethink It</Button>
                                </>
                            }
                        </div>
                    }
                    {!this.state.selected && 
                        <Button className="game__menu-button" style={{ fontSize: '25px', background: 'transparent', width: '200px', height: '50px' }} onClick={() => {this.setState({ selected: true, hued: false })}}>Illa</Button>
                    }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default HomePage;