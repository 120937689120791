import React, { Component } from 'react';

class EndGame extends Component {
    render() { 
        return (
            <div style={{ paddingTop: '150px' }}>
                <center>
                <h1 className="game__menu-button" style={{ paddingBottom: '30px', fontSize: '75px' }}>Neen hu annu</h1>
                <div className="game__score-title">
                    Congratulations !! Your Website is unlocked !<br/><br/>
                    hogana baa<br/><br/>
                    <a href="https://raksha.link">Raksha.link</a>
                </div>
                </center>
            </div>
        );
    }
}
 
export default EndGame;